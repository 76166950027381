import styled from "styled-components";

export const DivContainer = styled.div`
  width: 100%;
  background-color: #191923;
  display: flex;
  justify-content: space-between;
`;
export const Container = styled.div`
  margin-top: 2rem;
  position: relative;
  padding: 2rem 0;
  width: 100vw;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 3rem;
  }
`;

export const Profile = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .links {
    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      a {
        text-decoration: none;
        color: lightgray;
        margin-bottom: 0.5rem;
        padding-right: 10px;

        :hover {
          color: #01be96;
        }
      }
    }
  }

  .profiles {
    h1 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      a {
        text-decoration: none;
        color: lightgray;
        margin-bottom: 0.5rem;

        :hover {
          color: #01be96;
        }
      }
    }
  }
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #01be96;
  border: 0;
  border-radius: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;

  @media (max-width: 650px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    position: relative;
    margin: 0 auto;
  }
`;
