import { Container, Banner, LightColor } from "../src/styles/stylesApp";
import Header from "./components/Header/Header";
import ProfComponent from "./components/Banner/ProfComponent";
import Footer from "./components/Footer/Footer";
import Projects from "./components/Portfolio/Projects";
import Skills from "./components/Skills/Skills";
import React from "react";

function App() {
  return (
    <Container>
      <Banner>
        <Header />
        <ProfComponent />
      </Banner>
      <Skills />
      <LightColor>
        <Projects />
      </LightColor>
      <LightColor>
        <Footer />
      </LightColor>
    </Container>
  );
}

export default App;
