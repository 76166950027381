import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;

  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
export const Texts = styled.div`
  flex: 1;
  margin-top: 4rem;

  h4 {
    padding: 1rem 0;
    font-weight: 500;
  }
  h1 {
    font-size: 2rem;
    font-family: "Secular One", sans-serif;
    letter-spacing: 2px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-weight: 300;
  }

`;

export const Profile = styled.div`
  img {
    width: 30rem;
    transition: transform 400ms ease-in-out;
    border-radius: 50%;
    margin-right: 3rem;

    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      width: 18rem;
    }

    @media (max-width: 640px) {
      width: 90vw;
    }
  }

  :hover img {
    transform: translateY(-10px);
    filter: drop-shadow(0 10px 10px #01be9570);
  }
`;
