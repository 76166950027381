import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import Project from "../Portfolio/Project";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Buttons, Container } from "./stylesSlider";

let data = [
  {
    img: "https://github.com/Rodrigo-ooliveira/React-template-admin/blob/main/public/images/tela-login-autentica%C3%A7%C3%A3o.png?raw=true",
    disc: "Tela de login criada com React.js Next.js, usando Typescript, Tailwind CSS para estilização, com autenticação no firebase!",
    repoLink: "https://github.com/Rodrigo-ooliveira/React-template-admin",
  },
  {
    img: "https://github.com/Rodrigo-ooliveira/React-Quiz-dbz/blob/main/public/image/quizzdbz.png?raw=true",
    disc: "Quiz criado com React.js e Next.js, usando Typescript e ContextAPI!",
    repoLink: "https://github.com/Rodrigo-ooliveira/React-Quiz-dbz",
  },
  {
    img: "https://github.com/Rodrigo-ooliveira/React-clone-DIO/blob/main/public/assets/dio-clone.png?raw=true",
    disc: "Clone da pagina da plataforma DIO, criada com React.js e Styled Components, tela inicial, tela de login e cadastro!",
    repoLink: "https://github.com/Rodrigo-ooliveira/React-clone-DIO",
  },
  {
    img: "https://github.com/Rodrigo-ooliveira/Blog-flyfishing/blob/main/src/assets/flyffishing.png?raw=true",
    disc: "Blog criado para a prática de Angular, usado rotas, componentes dinâmicos com banco de dados para simulação!",
    repoLink: "https://github.com/Rodrigo-ooliveira/Blog-flyfishing",
  },
  {
    img: "https://github.com/Rodrigo-ooliveira/door-of-the-desperate/blob/main/public/assets/Door.png?raw=true",
    disc: "Projeto o problema de Monty-Hall, criado com React.js e Next.js com TypeScript!",
    repoLink: "https://github.com/Rodrigo-ooliveira/door-of-the-desperate",
  },
];

let settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

const SliderComp = () => {
  const arrowRef = useRef(null);
  let sliderProject = "";
  sliderProject = data.map((item, i) => <Project item={item} key={i} />);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        arrowRef.current.slickPrev();
      } else if (e.key === "ArrowRight") {
        arrowRef.current.slickNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
        {sliderProject}
      </Slider>
      <Buttons>
        <button onClick={() => arrowRef.current.slickPrev()} className="back">
          <IoIosArrowBack />
        </button>
        <button onClick={() => arrowRef.current.slickNext()} className="next">
          <IoIosArrowForward />
        </button>
      </Buttons>
    </Container>
  );
};

export default SliderComp;
