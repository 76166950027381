import React from "react";
import { Container, Slide } from "./stylesProjects";
import SliderComp from "../Slider/Slider";
import { Zoom } from "react-awesome-reveal";

const Projects = (github) => {
  return (
    <Container id="portfolio">
      <Zoom>
        <h1>
          <span className="green">Portfólio</span>
        </h1>
        <p>
          Nesta seção apresento alguns dos projetos que desenvolvi durante
          bootcamps e treinamentos que realizei!
        </p>
      </Zoom>
      <Slide>
        <SliderComp />
      </Slide>
    </Container>
  );
};
export default Projects;
