import React from "react";
import { Container } from "./stylesProject";

const Project = (props) => {
  const { img, disc, repoLink } = props.item;

  const openRepository = (repoLink) => {
    if (repoLink) {
      window.open(repoLink, "blank");
    }
  };

  return (
    <Container className="project">
      <img src={img} alt="project" />
      <div className="disc">
        <h1>Descrição</h1>
        <p>
          {disc}
          <span className="green" onClick={() => openRepository(repoLink)}>
            {" "}
            GitHub
          </span>
        </p>
      </div>
    </Container>
  );
};

export default Project;
