import { ArrowUp, Container, DivContainer, Profile } from "./stytlesFooter";
import { HiOutlineMailOpen } from "react-icons/hi";
import { AiFillLinkedin, AiOutlineArrowUp } from "react-icons/ai";
import { SiWhatsapp, SiGithub } from "react-icons/si";
import { Slide, Fade } from "react-awesome-reveal";

const Footer = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <DivContainer>
      <Container id="footer">
        <Profile>
          <div className="links">
            <Slide direction="left">
              <h1 className="green">Contato:</h1>
            </Slide>
            <div>
              <Slide direction="left">
                <span>
                  <SiWhatsapp />
                </span>
              </Slide>
              <Slide direction="left">
                <a
                  href="http://api.whatsapp.com/send?1=pt_BR&phone=5551993690934"
                  target="blank"
                >
                  WhatsApp
                </a>
              </Slide>
            </div>
            <div>
              <Slide direction="left">
                <span>
                  <HiOutlineMailOpen />
                </span>
              </Slide>
              <Slide direction="left">
                <a href="mailto:rodrigofoliveiradev@gmail.com">
                  rodrigofoliveiradev@gmail.com
                </a>
              </Slide>
            </div>
          </div>

          <div className="profiles">
            <Slide direction="left">
              <h1 className="green">Redes Sociais</h1>
            </Slide>

            <div>
              <Slide direction="left">
                <span>
                  <SiGithub />
                </span>
              </Slide>
              <Slide direction="right">
                <a href="https://github.com/Rodrigo-ooliveira" target="blank">
                  GitHub
                </a>
              </Slide>
            </div>
            <div>
              <Slide direction="right">
                <span>
                  <AiFillLinkedin />
                </span>
              </Slide>
              <Slide direction="right">
                <a
                  href="https://www.linkedin.com/in/rodrigofigueiradeoliveiradev/"
                  target="blank"
                >
                  Linkedin
                </a>
              </Slide>
            </div>
          </div>
          <Fade>
            <div>
              <ArrowUp onClick={scrollUp}>
                <AiOutlineArrowUp />
              </ArrowUp>
            </div>
          </Fade>
        </Profile>
      </Container>
    </DivContainer>
  );
};

export default Footer;
