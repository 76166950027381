import React from "react";
import { Container } from "./stylesCard";

const Card = (props) => {
  const { Icon, title } = props;
  return (
    <Container>
      <span>
        <Icon />
      </span>
      <p>{title}</p>
    </Container>
  );
};

export default Card;
