import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Buttons = styled.div`
  button {
    width: 2rem;
    height: 2rem;
    background-color: #01be96;
    border: 0;
    border-radius: 30%;
    cursor: pointer;
    color: #ffffff;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back {
    left: -1rem;
  }
`;
