import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 5rem 0;

  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    text-align: center;
    font-size: 3rem;
    padding-top: 0.1rem;
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
