import React from "react";
import {
  SiAngular,
  SiTypescript,
  SiBootstrap,
  SiJavascript,
  SiTailwindcss,
} from "react-icons/si";
import { RiReactjsLine } from "react-icons/ri";
import { TbBrandNextjs, TbBrandNodejs } from "react-icons/tb";
import { Cards, Container } from "./stylesSkills";
import Card from "../Card/Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="skills">
      <Slide direction="down">
        <h1 className="green">Skills</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card Icon={SiJavascript} title="Javascript" />
        </Slide>
        <Slide direction="left">
          <Card Icon={RiReactjsLine} title="React.js" />
        </Slide>
        <Slide direction="right">
          <Card Icon={SiAngular} title="Angular" />
        </Slide>
        <Slide direction="right">
          <Card Icon={SiTypescript} title="TypeScript" />
        </Slide>
        <Slide direction="left">
          <Card Icon={TbBrandNodejs} title="Node.js" />
        </Slide>
        <Slide direction="left">
          <Card Icon={TbBrandNextjs} title="Next.js" />
        </Slide>
        <Slide direction="right">
          <Card Icon={SiBootstrap} title="Bootstrap" />
        </Slide>
        <Slide direction="right">
          <Card Icon={SiTailwindcss} title="Tailwind CSS" />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;
